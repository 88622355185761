import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {Translation} from "react-i18next";
import variables from "../../variables/env.js";
import CustomToast from "./CustomToast";


class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      msg: props.msg,
      target:props.target || null,
      dataRadio:props.dataRadio || null,
      toastShow:false,
    };
    this.hideModal = props.hideModal;
    if (props.dataRatioOnchange) { this.dataRatioOnchange = props.dataRatioOnchange.bind(this); }
  }

  /*Whenever a class component is updated*/
  componentDidUpdate(prevProps) {
    // console.log('componentDidUpdate', prevProps, this.props);
    if (prevProps.show !== this.props.show) {
      this.setState({show: this.props.show});
      if (this.props.show === true){
         window.scrollTo(0, 0);
      }
    }
    if (prevProps.msg !== this.props.msg) {
      this.setState({msg: this.props.msg});
    }
    if (prevProps.target !== this.props.target) {
      this.setState({target: this.props.target});
    }
    if (prevProps.dataRadio !== this.props.dataRadio) {
      this.setState({dataRadio: this.props.dataRadio});
    }
  }

  validarCheckRadio(){
    if(!document.querySelector('input[name="otrosMetodo"]:checked')) {
      this.handleToast('bg-danger', 'Debe seleccionar un método de pago');
    }else{
      this.hideModal();
    }
  }

  handleToast = (status, msg) =>{
      this.setState({
        toastMsg:msg,
        toastShow:true,
        toastClass:status
      });
  }

  hideParentToast = () => {
    this.setState({toastShow:false});
  }

  render() {
    return (
      <Translation ns={['translation']}>
          {(t) =>
            <>
            <Modal
                size="md"
                show={this.state.show}
                onHide={() => this.hideModal() }
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                centered>
                <Modal.Header>
                <div className="result_header">
                    <div className="result_logo">  <a><img src={variables.appurl +"assets/img/"+variables.mienv+"/logo-white.png"} alt={variables.appname} /></a>  </div>
                </div>
                </Modal.Header>
                <CustomToast msg={this.state.toastMsg} show={this.state.toastShow} tclass={this.state.toastClass + ' d-block '} hideParentToast={() => (this.hideParentToast())}/>
                <Modal.Body className="h4 text-center">
                  {this.state.target === 'cesta' && <img src={variables.appurl + 'assets/img/icono-cesta.svg'}  className="icono-cesta"/> }
                  {!this.state.dataRadio && <>{ this.state.msg } </>}
                  {this.state.dataRadio && this.state.dataRadio.length > 0 &&
                      <div className="">
                        <div className="dashboard_tab_item pb-0">
                          <h6> { this.state.msg } </h6>
                          <FormControl component="fieldset" className="p-2">
                            <RadioGroup aria-label="radio" name="radio" onChange={this.dataRatioOnchange}>
                              { this.state.dataRadio.map((d, index) => {
                                return <FormControlLabel key={index}
                                                         value={d.id}
                                                         name={'otrosMetodo'}
                                                         control={<Radio />}
                                                         label={t(d.nombre)}
                                />
                              })}
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>

                  }
                </Modal.Body>
              <Modal.Footer>
                  <button type="button" className="btn btn btn-light" data-dismiss="modal" onClick={() => this.hideModal() }>{t('Cerrar')}</button>
                  {this.state.dataRadio && this.state.dataRadio.length > 0 &&
                      <Button className="btn btn-secondary btn-md" onClick={() => this.validarCheckRadio() }>{t('Continuar')}</Button>
                  }
                  {this.state.target === 'cesta' && <Button className="accent_btn btn-md" onClick={() => window.location.href = '/cesta-compra' }>{t('Ver Cesta')}</Button> }
                  {!this.state.dataRadio &&
                    <Button className="btn btn-secondary btn-md" onClick={() => this.hideModal() }>{t('Continuar')}</Button>
                  }
              </Modal.Footer>
            </Modal>
            </>
          }
      </Translation>
      );
  }
}

export default CustomModal;
