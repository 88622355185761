/*!
=========================================================
* VARIABLES APP
*/
/*****************************************/
import Auth from '../middlewares/Auth';
import Cookie from 'universal-cookie';
import getLanguage from '../language/getLanguage';

const cookie = new Cookie();
const nombreCookieCesta = process.env.REACT_APP_CESTA_COOKIE_NAME;
const dominio = process.env.REACT_APP_DOMAIN;
const lang = getLanguage();

const variables = {
    lang: lang,
    isdev: (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false,
    /*-- env --*/
    mienv: process.env.REACT_APP_MIENV,
    domain: process.env.REACT_APP_DOMAIN,
    appurl: process.env.REACT_APP_URL,
    appname:process.env.REACT_APP_NAME,
    apiurl: process.env.REACT_APP_API_URL,
    apiimgpath: process.env.REACT_APP_IMAGES_URL,
    adminurl: process.env.REACT_APP_ADMIN_URL,
    tpvkey: process.env.REACT_APP_TPV_KEY,
    /*-- auth --*/
    auth: (value) =>{
      switch(value) {
        case 'token':
          return Auth.getToken();
          break;
        case 'username':
          let username = Auth.getName() || '';
          return (username) ? username.replace(/ .*/,''):'';
          break;
        case 'metodosPagosApp':
          return Auth.getMetodosPagosApp() || {};
          break;
        case 'servicioRemesaActivo':
          return Number(Auth.getServicioRemesaActivo()); //1 or 0
          break;
        case 'metodosPagosDisponibles':
          return Auth.getMetodosPagosDisponibles() || [];
          break;
        default:
          return Auth.getAuth();
      }
    },
    logout: () => Auth.signOut(),
    /*-- metodos de pago --*/
    includeSaldo:() =>{
      const mpd =  Auth.getMetodosPagosDisponibles() || [];
      const mpapp = Auth.getMetodosPagosApp() || {};
      return (mpapp.saldo) ? mpd.includes(mpapp.saldo) : false;
    },
    /*-- cesta --*/
    setCountCesta: (value) => {
      const divCountCesta = document.getElementById("cart_menu_num");
      if (divCountCesta) divCountCesta.innerHTML = (value) ? value : '';
    },

    getIdCesta: () => {
      const idCesta = cookie.get(nombreCookieCesta);
      // console.log(nombreCookieCesta, idCesta);
      return idCesta;
    } ,
    setIdCesta: (id_cesta) => {
      const timestamp = new Date().getTime();
      const expiracion = timestamp + (60 * 60 * 24 * 1000);/** Expiración del cookies de 24h ***/
      const fechaExpiracion = new Date(expiracion);
      if (id_cesta){
          cookie.set(nombreCookieCesta, id_cesta, { path: '/', expires: fechaExpiracion, domain: dominio, sameSite: 'none',secure: true });
      }
    },
    removeIdCesta: () => {
        cookie.remove(nombreCookieCesta, { path: '/',domain:dominio ,sameSite: 'none',secure: true });
    },

    getCountCesta: () =>{
      const url = (Auth.getAuth()) ? 'cesta/cesta' : 'cesta/auxCesta';
      return 3;
      // const response = await fetch(AppEnv.apiurl +'index.php/servicios/contadorProductoCesta',{
      //     method: 'post',
      //     headers: {
      //         'Accept': 'application/json',
      //         'Content-Type': 'application/json'
      //     },
      //     body: JSON.stringify({
      //         cestaCookie : variables.getIdCesta(),
      //         token: variables.auth('token'),
      //     })
      // });
      // const data = await response.json();
      // if (data && data.result){
      //     let d = (String(data.result) === '0' || Number(data.result) === 0) ? '' : data.result;
      //     setData(d);
      // }else{
      //     setData('');
      // }
    }

};
export default variables;
